import { gsap } from "gsap";
import Link from "../components/link"
import React, { useEffect } from "react"
import ContactForm from "../components/contactForm"

const Overlay = () => {
  useEffect(() => {
    document.querySelector('.contact-form--button').addEventListener("click", showContactForm);
    window.hbspt.forms.create({
      region: "na1",
      portalId: "9226978",
      formId: "1decd293-dfd1-48d5-9846-26202f78a7e1",
      target: "#contact-form",
    })
  }, []);
  const showContactForm = (e) => {
    document.querySelector('.overlay--menu').classList.add('form-active');
    gsap.to('.internal--menu', { autoAlpha: 0, display: 'none', duration: .65 });
    gsap.to('.contact--form', { opacity: 1, display: 'block', duration: .65, delay: 0.75 });
  }

  return (
    <div className="overlay--menu">
      <div className="pl40 overlay--inner pr40">
        <div className="flex h-100 max-1400 ma w-100 overflow-x-hidden">
          <div className="max-450 w-33 m-hide"></div>
          <div className="flex-grow-1 m-hide"></div>
          <div className="max-450 w-33 flex flex-wrap max-unset m-w-100">
            <div className="menu internal--menu w-100 overlay--links uppercase ">
              <Link className="m0 link flex" to='/'>Home <span className="square bg-transition"></span></Link>
              <Link className="m0 link" to='/developments'>Developments <span className="square bg-transition"></span></Link>
              <Link className="m0 link" to='/story'>Our Story <span className="square bg-transition"></span></Link>
              <Link className="m0 link" to='/insights'>Insights <span className="square bg-transition"></span></Link>
              <Link className="m0 link" to='/news'>News <span className="square bg-transition"></span></Link>
              <p className="m0 contact-form--button link">Contact <span className="square bg-transition"></span></p>
            </div>
            <div className="contact--form ">
              <div className='h-100 flex'>
                <div class='mta' id='contact-form'>
                  
                </div>
              </div>
            </div>
            <div className="contact information w-100 mta pb40 pt40 uppercase">
              <div className="flex">
                <div className="details">
                  <p className="m0 mb10">Details</p>
                  <a href="tel:1300 474 372" className="link ul-link mb5 inline">1300 474 372</a><br />
                  <a href="mailto:admin@sieragroup.com.au" className="link ul-link mb5 inline">admin@sieragroup.com.au</a>
                  <p className='m0'>  
                  Level 6/ 145 Eagle Street,<br />
                  Brisbane City,<br /> 
                  QLD 4000</p>
                  <div className="m-show m-pt20">
                    <span className="m0">ACN: 643 263 272</span> <br/> <span className="m0">ABN: 86 643 263 272</span> <br/> <span className="m0">QBCC: 15336339</span>

                  </div>
                </div>
                <div className="details mla">
                  <p className="m0 mb10">Follow</p>
                  <a href="https://www.facebook.com/sieragroup/" rel="noreferrer" target='_blank' className="link ul-link mb5 inline">Facebook</a><br />
                  <a href="https://www.instagram.com/sieragroup/" rel="noreferrer" target='_blank' className="link ul-link mb5 inline">instagram</a><br />
                  <a className="link ul-link mb5 inline" target="_blank" rel="noreferrer" href="https://www.youtube.com/channel/UCvCNr4mtCh0Ob4nwH_7RStA">Youtube</a><br/>
                  <a  href="https://www.linkedin.com/company/siera-group" rel="noreferrer" target='_blank' className="link ul-link mb5 inline">Linkedin</a>
                </div>
              </div>
              <div className="spacer"></div>
              <p className="m0 flex justify-between slim m-hide"><span>ACN: 643 263 272</span> <span>|</span> <span>ABN: 86 643 263 272</span> <span>|</span> <span>QBCC: 15336339</span></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default Overlay
