import React, { useEffect } from "react"
import {throttle} from 'lodash'

const Scrolling = () => {
  
  useEffect(() => { 
    scrollTracker()
    const throttledScroll = throttle(scrollTracker,100)

    window.addEventListener('scroll',throttledScroll)
    return () =>  window.removeEventListener('scroll',throttledScroll)
  
   

    function scrollTracker () {
      const progress = (window.scrollY/300)
      const opacity =  ((100 - (100 * progress))/100)
     
      if(document.querySelector('.scrolling--banner')) {
        document.querySelector('.scrolling--banner .bg-image').style.opacity =  opacity;
        document.querySelector('.masthead')?.classList.add('is-dark')
        if(window.scrollY > 10) {
          //document.querySelector('.banner--title').classList.remove('text-white');
          document.querySelector('.masthead').classList.remove('is-dark')

          document.querySelectorAll('.text-will-change').forEach((el) =>{
            el.classList.remove('is-dark');
          })

          document.querySelector('.background-will-change')?.classList.remove('is-dark')
          
        } else {
          //document.querySelector('.scrolling--banner .bg-image').classList.remove('fade-out');
          //document.querySelector('.banner--title').classList.add('text-white');
          document.querySelectorAll('.text-will-change').forEach((el) =>{
            el.classList.add('is-dark');
          })
          document.querySelector('.masthead').classList.add('is-dark');
          document.querySelector('.background-will-change')?.classList.add('is-dark')
        }
      } 
    } 

   

  },[]);
  return (
    <></>
  ) 
}

export default Scrolling
 