import { gsap } from "gsap";

export const toggleMenu = (e) => {
    var menu = document.querySelector('.overlay--menu');
    var masthead = document.querySelector('.masthead');
    showContactForm();
    menu.classList.add('is-active');
    masthead.classList.add('is-open');
    document.querySelector('.menu--copy').innerHTML = 'Close';
    
}

export const showContactForm = (e) => {
    gsap.to('.internal--menu',  {autoAlpha: 0, display:'none', duration: 0});
    gsap.to('.contact--form',  {opacity: 1, display:'block', duration: 0});
    document.querySelector('.overlay--menu').classList.add('form-active');
}