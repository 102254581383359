import React from "react"
import TransitionLink from 'gatsby-plugin-transition-link'
import { gsap } from "gsap";

const Link = ({ children, ...props }) => {
  return (
    <TransitionLink  
    {...props}
      exit={{

        length: 1,
        trigger: ({ node }) => {
          gsap.to(node, { opacity: 0, duration: 0.85, ease: "power3.inOut" })
        }
      }}
      entry={{
        delay: 1.05,
        state: { internal: true },
        trigger: ({ node }) => {
          gsap.to(node, { opacity: 0, duration: 0 })
          gsap.to(node, { opacity: 1, duration: .85, delay:0.3, ease: "power3.inOut" })
        },
      }}>
      {children}
    </TransitionLink>
  )
}
export default Link