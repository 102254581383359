import axios from "axios"
import React, { useState } from "react"

const fields = () => {
  const fields = []

  function addField({ name, type, require, element = 'input', values = [] }) {
    fields.push({ name, type, require, element, values })
    return { addField, getField }
  }

  function getField() {
    return fields
  }

  return { addField, getField }

}

const formFields = fields()
  .addField({ name: 'First Name', type: 'text', require: true })
  .addField({ name: 'Last Name', type: 'text', require: true })
  .addField({ name: 'email', type: 'email', require: true })
  .addField({ name: 'Mobile', type: 'tel', require: true })
  .addField({ name: 'Postcode', type: 'number', require: true })
  .addField({ name: 'ENQUIRY TYPE', element: 'dropdown', require: true, values: ['Enquiry Type', 'General', 'Development'] })
  .addField({ name: 'message', element: 'textarea', require: false })
  .getField();

const Form = ({ fields, children }) => {


  const [submitted, setSubmitted] = useState(false)
  const submit = async (e) => {
    e.preventDefault()

    try {
      const form = e.target.closest('form')

      const data = new FormData(form);
      console.log(data.get('name'))
      if (data.get("First Name") && data.get("Last Name") && data.get("email") && data.get('Mobile') && data.get('Postcode')) {
        const encodedData = new URLSearchParams(data);

        await axios.post('/', encodedData);
        setSubmitted(true)
      }


    } catch (e) {
      console.error(e)
    }
  }

  return (
    <div className="pos-rel w-100">
      <form style={{ opacity: submitted ? 0 : 1 }} name="contact" method="POST" data-netlify="true" className='   w-100  opacity-transition'>
        <input type="hidden" name="form-name" value="contact" />
        {fields.map(({ element, name, values }, index) => {
          switch (element) {
            case 'dropdown':
              return <select required className='input' key={index} name={name} placeholder={name}>
                {values.map((value, index) => {
                  return <option key={index} value={value}>{value.toUpperCase()}</option>
                })}
              </select>
            case 'textarea':
              return <textarea required key={index} className='input flex-grow-1' name={name} placeholder={name.toUpperCase()} />
            default:
              return <input required key={index} className="input" name={name} placeholder={name.toUpperCase()} />
          }
        })}
        <div className="flex pt20 ">
          <div className="">
            <input defaultChecked={true} type="checkbox" id="mailing-list" name="mailing-list" />
          </div>
          <div className=" ml20 ">
            <h4 className=" footer--small--text m0 uppercase ">I agree to RECEIVING MARKETING COMMUNICATION ABOUT SIERA. <br/> you can unsubscribe at any time.</h4>
          </div>
        </div>

        <button onClick={submit}>
          {children}
        </button>
      </form>
      <div style={{ opacity: submitted ? 1 : 0 }} className={"h3 pos-abs  opacity-transition-1  pointer-none top-0  m-top-40   "}>
        Thanks for your Submission
      </div>
    </div>
  )
}

const ContactForm = () => {
  return (
    <div className="contact-form flex  align-bottom h-100">
      <Form fields={formFields} >
        <p className='button text-white white'> Submit</p>
      </Form>
    </div>
  )
}

export default ContactForm
